<template>
    <SwitchGroup as="div" class="flex flex-col">
        <SwitchLabel as="span" class="text-sm font-medium text-gray-700" v-if="label">
            {{ label }}
        </SwitchLabel>
        <Switch
            v-model="enabled"
            :disabled="disabled"
            @update:modelValue="$emit('update:modelValue', enabled)"
            :class="[
                {'bg-gray-200': !modelValue},
                {'bg-gray-900/30': disabled && modelValue},
                {'bg-primary': modelValue && !disabled},
                'relative inline-flex mt-1 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2',
            ]"
        >
            <span
                aria-hidden="true"
                :class="[
                    modelValue ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                ]"
            />
        </Switch>
    </SwitchGroup>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

    const props = defineProps<{
        label?: string;
        name: string;
        modelValue: any;
        disabled?: boolean;
    }>();

    const enabled = ref(props.modelValue);
</script>
