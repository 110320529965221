<template>
    <PPHeader title="Gebruikers" href="new-user" />

    <div class="mt-3">
        <PPDataTable
            :headers="headers"
            resourceUrl="/admin/users"
            :rowClicked="(row) => $router.push({ name: 'edit-user', params: { id: row.id } })"
            :filter="true"
        />
    </div>
</template>

<script setup lang="ts">
    import PPDataTable from '@/components/PPDataTable.vue';
    import PPHeader from '@/components/PPHeader.vue';

    // import User from '@/types/user'

    const headers = [
        // {key: 'id', title: 'ID'},
        { key: 'firstName', title: 'Voornaam' },
        { key: 'lastName', title: 'Achternaam' },
        { key: 'email', title: 'E-mailadres' }
    ];
</script>
