<template>
    <DataTable
        :rows="tableData"
        @loadData="getDataTable"
        :pagination="pagination"
        @row-clicked="rowClicked"
        :query="queries"
        hoverable
        sortable
        :filter="filter"
        :key="tableKey"
    >
        <template #thead="{ sorting, sort }">
            <TableHead
                v-for="header in headers"
                :key="header.key"
                :sortable="header.key"
                multiple
                :sort="sort"
                @sorting="sorting"
            >
                {{ header.title }}
            </TableHead>
        </template>

        <template #tbody="{ row }">
            <TableBody :key="item.key" v-for="item in headers">
                <slot :name="`cell(${item.key})`" :value="row[item.key]" :item="item" :row="row">
                    <template v-if="typeof row[item.key] === 'boolean'">
                        <Component :is="row[item.key] ? CheckIcon : XMarkIcon" class="w-5 h-5" />
                    </template>

                    <template v-else>
                        {{ row[item.key] }}
                    </template>
                </slot>
            </TableBody>
        </template>
    </DataTable>
</template>

<script setup lang="ts">
    import { inject, ref, watch } from 'vue';
    import { DataTable, TableBody, TableHead } from '@jobinsjp/vue3-datatable';
    import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline';

    import type { DataTableHeader } from '@/types/datatable-header';

    const axios = inject('axios');

    const props = defineProps<{
        headers: DataTableHeader[];
        filter: boolean;
        resourceUrl: string;
        dtoClass?: any;
        rowClicked: any;
        filters?: {};
    }>();

    const tableData = ref([]);
    const tableKey = ref(0);

    const queries = {
        sort: null,
        search: null
    };
    const pagination = ref({
        per_page: 10,
        page: 1,
        total: 0
    });
    let draw = ref(0);

    let error = ref('');

    // Check if filters are changed and reload the datatable
    watch(
        () => props.filters,
        () => tableKey.value++,
        { deep: true }
    );

    const getDataTable = async (query: any) => {
        let params = {
            perPage: query.per_page,
            page: query.page,
            search: query.search,
            sort: {},
            filter: props.filters
        };

        if (query.sort) {
            let sortColumns = query.sort.split(',');

            sortColumns.forEach((column) => {
                const columnSort = column.split(':');

                params.sort[columnSort[0]] = columnSort[1].toUpperCase();
            });

            queries.sort = query.sort;
            queries.search = query.search;
        }

        try {
            const response = await axios.get(props.resourceUrl, { params: params });

            pagination.value = {
                ...pagination.value,
                page: query.page,
                total: response.data.total
            };
            tableData.value = formatData(response.data.data);
            draw.value++;
        } catch (e) {
            error.value = 'Something went wrong while fetching resource.';
        }
    };

    const formatData = (data) => {
        if (props.dtoClass) {
            return data.map((dataDto) => new props.dtoClass(dataDto));
        }

        return data;
    };
</script>
