<template>
    <PPHeader :title="editMode ? 'Gebruiker details' : 'Nieuwe gebruiker'" />

    <form @submit.prevent="submit" class="my-5">
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPInput
                v-model="userForm.firstName"
                name="first_name"
                label="Voornaam"
                :errors="v$.firstName.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPInput
                v-model="userForm.lastName"
                name="last_name"
                label="Achternaam"
                :errors="v$.lastName.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPInput
                type="email"
                v-model="userForm.email"
                name="email"
                label="E-mailadres"
                :errors="v$.email.$errors.length"
                autocomplete="email"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2" v-if="!editMode">
            <PPToggle name="sendInvite" v-model="userForm.sendInvite" label="Stuur uitnodiging" />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2" v-if="!userForm.sendInvite && !editMode">
            <PPInput
                type="password"
                v-model="userForm.password"
                name="password"
                label="Wachtwoord"
                :errors="v$.sendInvite.$errors.length"
                autocomplete="new-password"
            />
        </div>

        <hr class="my-5" />

        <PPAlert class="mb-3" :error="error" />

        <PPButton type="submit">Opslaan</PPButton>
        <PPButton class="ml-5" v-if="editMode" color="danger" @click="deleteUser"
            >Verwijder
        </PPButton>
    </form>
</template>

<script setup lang="ts">
    import PPInput from '@/components/PPInput.vue';
    import PPButton from '@/components/PPButton.vue';
    import PPHeader from '@/components/PPHeader.vue';
    import PPAlert from '@/components/PPAlert.vue';
    import PPToggle from '@/components/PPToggle.vue';

    import { inject, onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useFlashMessage } from '@/stores/flash-message';

    import type { AxiosResponse } from 'axios';
    import type { User } from '@/types/api/response/user';
    import { FlashMessageType } from '@/types/flash-message';

    // Form and validation
    import { useVuelidate } from '@vuelidate/core';
    import { email, required } from '@vuelidate/validators';

    const axios = inject('axios');

    const route = useRoute();
    const router = useRouter();
    const flashMessage = useFlashMessage();
    const editMode = ref(false);
    const user = ref({});

    // Types
    type UserForm = {
        id?: string;
        firstName: string;
        lastName: string;
        email: string;
        password?: string;
        sendInvite: boolean;
    };

    onMounted(async () => {
        if (route.params.id) {
            await getUser();
            editMode.value = true;
        }
    });

    const getUser = async () => {
        try {
            const response: AxiosResponse<User> = await axios.get(`/admin/user/${route.params.id}`);

            user.value = response.data;
            userForm.value = Object.assign({}, userForm.value, user.value);
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const deleteUser = async () => {
        try {
            const response = await axios.delete(`/admin/user/${route.params.id}`);

            if (response.status === 204) {
                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: 'Successfully deleted user!'
                });
            }

            await router.push({ name: 'users' });
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const userForm = ref<UserForm>({
        id: undefined,
        firstName: '',
        lastName: '',
        email: '',
        password: undefined,
        sendInvite: true
    });

    const rules = {
        firstName: {},
        lastName: {},
        email: { required, email },
        sendInvite: {}
    };

    const v$ = useVuelidate(rules, userForm);
    const error = ref('');

    // Form submission
    const submit = async () => {
        const result = await v$.value.$validate();

        if (!result) {
            return;
        }

        error.value = '';

        const url = editMode.value ? `/admin/user/${route.params.id}` : '/admin/user';
        const method = editMode.value ? 'PATCH' : 'POST';
        const data: any = userForm.value;

        try {
            const response = await axios({ method, url, data });

            if (response.status === 200 || response.status === 201) {
                const action = editMode.value ? 'updated' : 'created';
                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: `Successfully ${action} user!`
                });
            }

            await router.push({ name: 'users' });
        } catch (e) {
            error.value = e.data.detail;
            console.error('Something went wrong while updating or creating user!', e);
        }
    };
</script>
