<template>
    <div>
        <label :for="name" class="block text-sm font-medium text-gray-700 mb-1" v-if="label">{{ label }}</label>
        <div>
            <input
                :value="inputValue"
                @input="onInput($event)"
                :type="type"
                :placeholder="placeholder ?? label"
                :name="name"
                :readonly="readonly"
                :disabled="disabled"
                :autocomplete="autocomplete"
                :step="step"
                :class="[inputClass, errors ? 'border-danger' : 'border-gray-300']"
                class="focus:ring-primary focus:border-primary block w-full text-sm rounded-sm transition duration-300"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';

    export interface Props {
        disabled?: boolean;
        errors?: number;
        label?: string;
        modelValue: null;
        name: string;
        placeholder?: string;
        readonly?: boolean;
        type?: string;
        step?: string | number;
        autocomplete?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        type: 'text'
    });

    const inputClass = computed(() => {
        if (props.disabled) {
            return 'bg-gray-200';
        }

        return '';
    });

    const inputValue = computed(() => {
        if (props.type === 'date' && props.modelValue) {
            const result = props.modelValue.match(/^(\d{4}-\d{2}-\d{2})T?/);
            return result[1] ?? '';
        }

        return props.modelValue;
    });

    const onInput = (event) => {
        let value = event.target.value;

        if (props.type === 'number') {
            value = props.step !== undefined ? parseFloat(value) : parseInt(value);
        }

        if (props.type === 'date' && value === '') {
            value = null;
        }

        emits('update:modelValue', value);
    };

    const emits = defineEmits<{
        (e: 'update:modelValue', value: string | number): void;
    }>();
</script>
