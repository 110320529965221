export enum FlashMessageType {
    Error = 'error',
    Warning = 'warning',
    Success = 'success',
    Info = 'info'
}

export type FlashMessage = {
    type: FlashMessageType;
    message: string;
};
