import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { ref } from 'vue';
import type { FlashMessage } from '@/types/flash-message';

export const useFlashMessage = defineStore('flashMessage', () => {
    const messages: Ref<FlashMessage[]> = ref([]);

    function all(): FlashMessage[] {
        const res = messages.value;

        clear();

        return res;
    }

    function first(): FlashMessage | undefined {
        return messages.value.shift();
    }

    function last(): FlashMessage | undefined {
        return messages.value.pop();
    }

    function add(message: FlashMessage) {
        messages.value.push(message);
    }

    function hasAny(): boolean {
        return messages.value.length > 0;
    }

    function clear() {
        messages.value = [];
    }

    return {
        messages,
        add,
        clear,
        hasAny,
        first,
        last,
        all
    };
});
