<template>
    <button
        type="button"
        :class="[colorClass, sizeClass]"
        class="mt-1 items-center px-4 py-2 text-sm font-medium rounded-sm shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-300"
    >
        <slot />
    </button>
</template>

<script setup lang="ts">
    import { computed } from 'vue';

    const props = defineProps<{
        color?: string;
        size?: string;
    }>();

    const colorClass = computed(() => {
        if (props.color === 'danger') {
            return 'border border-danger bg-danger hover:bg-danger/80 focus:ring-danger';
        }

        return 'border border-primary bg-primary hover:bg-primary/80 focus:ring-primary';
    });

    const sizeClass = computed(() => {
        if (props.size === 'block') {
            return 'block w-full justify-center';
        }

        return 'inline-flex';
    });
</script>
