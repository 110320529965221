<template>
    <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
            <h2
                class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
            >
                {{ title }}
            </h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0" v-if="props.href">
            <PPButton @click="$router.push({ name: props.href })">Creëer</PPButton>
        </div>
    </div>
</template>

<script setup lang="ts">
    import PPButton from '@/components/PPButton.vue';

    const props = defineProps<{
        title: string;
        href?: string;
    }>();
</script>
