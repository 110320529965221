<template>
    <div v-if="value" :class="divClass">
        <div class="ml-3">
            <p :class="textClass">{{ value }}</p>
        </div>
    </div>
</template>

<script lang="ts">
    export default {
        props: ['success', 'error', 'info', 'message'],
        computed: {
            divClass() {
                if (this.error || this.message?.type === 'error') {
                    return 'bg-red-50 border-l-4 border-red-400 p-4 rounded-r-sm';
                }

                if (this.success || this.message?.type === 'success') {
                    return 'bg-green-50 border-l-4 border-green-400 p-4 rounded-r-sm';
                }

                return 'bg-blue-50 border-l-4 border-blue-400 p-4 rounded-r-sm';
            },
            textClass() {
                if (this.error || this.message?.type === 'error') {
                    return 'text-sm text-red-700';
                }

                if (this.success || this.message?.type === 'success') {
                    return 'text-sm text-green-700';
                }

                return 'text-sm text-blue-700';
            },
            value() {
                if (this.message) return this.message.message;
                if (this.error) return this.error;
                if (this.success) return this.success;
                if (this.info) return this.info;

                return '';
            }
        }
    };
</script>
